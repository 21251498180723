// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { TemplateAPIs } from '../../../../../src/apis'

// SweetAlert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const defaultParams = {
  q: "",
  page: 1,
  isParamChanged: true
}

export const getData = createAsyncThunk('appTemplate/getData', async params => {
  const response = await TemplateAPIs.WebAppList({
    ...params,
    search: params.q
  })

  return {
    params,
    data: response.data.list,
    allData: response.data.list,
    totalPages: response.data.records
  }

})

export const getOneData = createAsyncThunk('appDepartment/getOneData', async params => {
  try {
    if (params) {
      const response = await TemplateAPIs.findOne(params)
      return {
        templateDetail: response.data
      }
    }
    
    return {
      templateDetail: []
    }
  } catch (error) {
    showErrorToast(error)
  }
})


export const clearData = createAsyncThunk('appTemplate/clearData', () => {
  return {
    data: [],
    allData: [],
    totalPages: 0
  }
})

export const removeData = createAsyncThunk('appTemplate/removeData', () => {
  return {
    data: [],
    allData: [],
    totalPages: 0
  }
})

export const setModalData = createAsyncThunk('appTemplate/setModalData', async params => {
  return {
    modalData: {
      isModalOpen: params.isModalOpen,
      id: params._id
    }
  }
})


export const deleteTemplate = createAsyncThunk('appTemplate/deleteTemplate', async (data, { dispatch }) => {
  const {id, selectedSiteID} = data
  await TemplateAPIs.deleteOne({ templateID: id })
  await dispatch(getData({...defaultParams, siteID: selectedSiteID}))
  await dispatch(setModalData({ _id: '', isModalOpen: false }))
  return id
})

export const addChecklistTemplate = createAsyncThunk('appTemplate/addTemplate', async (templatesData, { dispatch }) => {
  const selectedSiteID = templatesData.selectedSiteID
	delete templatesData.selectedSiteID
  await TemplateAPIs.createNewOne({ body: templatesData })
  await dispatch(getData({...defaultParams, siteID: selectedSiteID}))
})

export const editChecklistTemplate = createAsyncThunk('appTemplate/editTemplate', async (templatesData, { dispatch }) => {
  await TemplateAPIs.updateOne({ body: templatesData })
  await dispatch(getData(defaultParams))
})


export const appInvoiceSlice = createSlice({
  name: 'appTemplate',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    modalData: {
      isModelOpen: false,
      id: ''
    },
    templateDetail: {
      _id: null,
      name: '',
      decsription: '',
      checkListItems: []
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      if (action.payload.params.isParamChanged) {
        state.data = action.payload.data
      } else {
        state.data = [...state.data, ...action.payload.data]
      }

      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    }).addCase(getOneData.fulfilled, (state, action) => {
      state.templateDetail = action.payload.templateDetail
    }).addCase(setModalData.fulfilled, (state, action) => {
      state.modalData = action.payload.modalData
    })
      .addCase(removeData.fulfilled, (state, action) => {
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
        state.data = action.payload.data
        state.templateDetail = action.payload.templateDetail
      })
  }
})

export default appInvoiceSlice.reducer
