import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.supplierTypeRoute.root

export const webAppList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.supplierTypeRoute.webAppList
		})
	)?.data
}

export const createNewOne = async(parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.supplierTypeRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}

export default {
    webAppList,
	createNewOne
}