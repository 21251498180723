import endPoint from "./_endPoint" 
import { axiosCallAdvanced } from "./_main"

const rootPath = endPoint.staffRoute.root

export const webAppList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.webAppList
        })
    )?.data
}

export const createNewOne = async(parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}
export const resendInvite = async(parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.resendInvite,
            method: endPoint.methodType.post
        })
    )?.data
}


export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export const deleteOne = async (parameters) => {
    const path = endPoint.staffRoute.deleteOne.replace(':id', parameters.staffID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path,
            method: endPoint.methodType.delete
        })
    )?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.staffRoute.findOne.replace(':id', parameters.staffID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export const simpleList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.simpleList
        })
    )?.data
}

export const simpleList2 = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.simpleList2,
            method: endPoint.methodType.post
        })
    )?.data
}


export const updateStatus = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.updateStatus,
            method: endPoint.methodType.patch
        })
    )?.data
}


export const downloadStaffData = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.downloadStaffData
        })
    )?.data
}

export const uploadStaff = async(parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.staffRoute.uploadStaff,
            method: endPoint.methodType.post
        })
    )?.data
}

export const getTasksForAssign = async (parameters) => {
    return (
        await axiosCallAdvanced({
            body: {
                siteID: parameters.body.siteID,
                userID: parameters.body.userID
            },
            baseURL: rootPath,
            path: endPoint.staffRoute.getTasksForAssign,
            method: endPoint.methodType.post
        })
    )?.data
}

export const bulkAssign = async(parameters) => {
    return (
        await axiosCallAdvanced({
            body: {
                removedTasks: parameters.body.removedTasks,
                tasks: parameters.body.tasks,
                assigneeID: parameters.body.assigneeID
            },
            baseURL: rootPath,
            path: endPoint.staffRoute.bulkAssign,
            method: endPoint.methodType.post
        })
    )?.data
}

export default {
    webAppList,
    findOne,
    createNewOne,
    updateOne,
    deleteOne,
    simpleList,
    updateStatus,
    downloadStaffData,
    uploadStaff,
    resendInvite,
    getTasksForAssign,
    bulkAssign,
    simpleList2
}

