// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AdminTemplateAPIs } from '../../../../apis'


export const clearData = createAsyncThunk('appAdminTemplate/clearData', () => {
  return {
    data: [],
    allData: [],
    totalPages: 0
  }
})

export const setModalData = createAsyncThunk('appDepartment/setModalData', async params => {
	return {
	  modalData: {
		isModalOpen: params.isModalOpen,
		id: params._id
	  }
	}
})

export const getData = createAsyncThunk('appAdminTemplate/getData', async params => {
	const response = await AdminTemplateAPIs.simpleList({
		...params,
		search: params.q
	})

	return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: response.data.records
	}
})

export const getOneData = createAsyncThunk('appAdminTemplate/getOneData', async params => {
	try {
		const response = await AdminTemplateAPIs.findOne(params)

		return {
			admintemplateDetail: response.data
		}
	} catch (error) {
		showErrorToast(error)
	}
})
export const importOne =  createAsyncThunk('appAdminTemplate/addTemplate', async (templatesData, { dispatch }) => {
  await AdminTemplateAPIs.importNewOne({ body: templatesData })
	await dispatch(getData(defaultParams))

})
export const appInvoiceSlice = createSlice({
    name: 'appAdminTemplate',
    initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      admintemplateDetail: {
        _id: null,
        name: '',
        decsription: '',
        checkListItems: []
      }
    },
    reducers: {},
    extraReducers: builder => {
      builder.addCase(getData.fulfilled, (state, action) => {
            if (action.payload.params.isParamChanged) {
                  state.data = action.payload.data
              } else {
                  state.data = [...state.data, ...action.payload.data]
              }
  
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
        state.params = action.payload.params
      }).addCase(getOneData.fulfilled, (state, action) => {
                  state.admintemplateDetail = action.payload.admintemplateDetail
          })
    }
  })


  export default appInvoiceSlice.reducer
  