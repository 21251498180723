import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SupplierAPIs, supplierTypeAPIs} from '../../../../apis'

const defaultParams = {
	q: "",
	page: 1,
	isParamChanged: true
}

export const getData = createAsyncThunk('appSupplier/getData', async params => {
	const response = await SupplierAPIs.webAppList({
		...params,
		search: params.q
	  })
	
	  return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: response.data.records
	  }
})
export const clearData = createAsyncThunk('appSupplier/clearData', () => {
	return {
	  data: [],
	  allData: [],
	  totalPages: 0
	}
  })
  
  export const getOneData = createAsyncThunk('appSupplier/getOneData', async params => {
	try {
	  const response = await SupplierAPIs.findOne(params)
  
	  return {
		supplierDetail: response.data
	  }
	} catch (error) {
	  showErrorToast(error)
	}
  })

  export const setModalData = createAsyncThunk('appSupplier/setModalData', async params => {
	return {
	  modalData: {
		isModalOpen: params.isModalOpen,
		id: params._id
	  }
	}
  })
  export const addSupplier = createAsyncThunk('appSupplier/addSupplier', async (supplierData, { dispatch, getState}) => {
	// if (supplierData.selectedSiteID) {
	// 	defaultParams.siteID = supplierData.selectedSiteID
	// }
	defaultParams.siteID = supplierData.selectedSiteID
	delete supplierData.selectedSiteID
	await SupplierAPIs.createNewOne({ body: supplierData })
	// await dispatch(getData(defaultParams))
	await dispatch(getData({
		...getState().supplier.params
	}))
})

export const getDataSupplierType = createAsyncThunk('appSupplierType/getDataSupplierType', async params => {
	const response = await supplierTypeAPIs.webAppList({
		...params,
		search: params.q
	})

	const optionDisplaySupplierTypeList = response.data.map((detail) => {
		return {
			value: detail._id,
			label: detail.name
		}
	})
	
	return {
		params,
		supplierTypeData: response.data,
		optionDisplaySupplierTypeList
	}
})

export const addSupplierType = createAsyncThunk('appSupplierType/addSupplierType', async (data, { dispatch }) => {
	await supplierTypeAPIs.createNewOne({ body: data })
	await dispatch(getDataSupplierType(defaultParams))
})

export const deleteSupplier = createAsyncThunk('appSupplier/deleteSupplier', async (data, {dispatch, getState}) => {
	// if (data.selectedSiteID) {
	// 	defaultParams.siteID = data.selectedSiteID
	// }
	defaultParams.siteID = data.selectedSiteID
	await SupplierAPIs.deleteOne({ supplierID: data.id })
	// await dispatch(getData(defaultParams))
	await dispatch(getData({
		...getState().supplier.params
	}))
	await dispatch(setModalData({_id : '', isModalOpen: false}))
	return data.id
})

  export const editSupplier = createAsyncThunk('appSupplier/editSupplier', async (supplierData, { dispatch }) => {
	await SupplierAPIs.updateOne({ body: supplierData })
	await dispatch(getData(defaultParams))
  })


export const appInvoiceSlice = createSlice({
	name: 'appSupplier',
	initialState: {
		data: [],
		optionDisplaySupplierTypeList: [],
		supplierTypeData: [],
		total: 1,
		params: {},
		allData: [],
		modalData: {
			isModelOpen :false,
			id: ''
		},
		supplierDetail:{
			_id:null,
			supplierName: "",
			supplierContactPersonName: "",
			email:"",
			mobileNumber:"",
			siteID: ""
		}
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getData.fulfilled, (state, action) => {
			if (action.payload.params.isParamChanged) {
				state.data = action.payload.data
			} else {
				state.data = [...state.data, ...action.payload.data]
			}
			

			state.allData = action.payload.allData
			state.total = action.payload.totalPages
			state.params = action.payload.params
		})
		.addCase(getOneData.fulfilled, (state, action) => {
			state.supplierDetail = action.payload.supplierDetail
		})
		.addCase(getDataSupplierType.fulfilled, (state, action) => {
			state.supplierTypeData = action.payload.supplierTypeData
			state.optionDisplaySupplierTypeList = action.payload.optionDisplaySupplierTypeList
		})
		.addCase(setModalData.fulfilled, (state, action) => {
			state.modalData = action.payload.modalData
		  })
	}
})
export default appInvoiceSlice.reducer
