// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/dashboard/invoice/store'
import department from '@src/views/dashboard/department/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import permissions from '@src/views/apps/roles-permissions/store'
import orgnization from './auth'
import userProfile from './userProfile'
import templates from  '@src/views/dashboard/templates/store'
import taskCategories from '@src/views/dashboard/taskcategory/store'
import staff from '@src/views/dashboard/staff/store'
import adminTemplate from '@src/views/dashboard/adminTemplate/store'
import task from '@src/views/dashboard/task/store'
import supplier from '@src/views/dashboard/supplier/store'
import foodItem from '@src/views/dashboard/fooditem/store'
import prob from '@src/views/dashboard/prob/store'
import fridge from '@src/views/dashboard/fridge/store'
import freezer from '@src/views/dashboard/freezer/store'
import temperatureLog from '@src/views/dashboard/temperatureLog/store'
import globalVariable from '@src/@core/layouts/components/menu/vertical-menu/verticalStore.js'
import complianceLog from '@src/views/dashboard/complianceLog/store'
import document from '@src/views/dashboard/documentManagement/store'

import common from '../redux/common'
const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  department,
  permissions,
  orgnization,
  userProfile,
  templates,
  taskCategories,
  staff,
  adminTemplate,
  task,
  supplier,
  foodItem,
  prob,
  fridge,
  freezer,
  temperatureLog,
  complianceLog,
  common,
  globalVariable,
  document
}

export default rootReducer
