import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.templatesRoute.root

export const createNewOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.templatesRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.templatesRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export const deleteOne = async (parameters) => {
    const path = endPoint.templatesRoute.deleteOne.replace(':id', parameters.templateID)
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path,
            method: endPoint.methodType.delete
        })
    )?.data
}

export const simpleList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.templatesRoute.SimpleList
        })
    )?.data
}

export const WebAppList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.templatesRoute.webAppList
        })
    )?.data
}


export const findOne = async (parameters) => {
    const path = endPoint.templatesRoute.findOne.replace(':id', parameters.templateID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export default {
    simpleList,
    createNewOne,
    updateOne,
    deleteOne,
    WebAppList,
    findOne
}

