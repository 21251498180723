import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.complianceLogRoute.root


export const complianceLogList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.complianceLogRoute.webAppListForWeb
		})
	)?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.complianceLogRoute.findOne.replace(':id', parameters.logID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.complianceLogRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export default {
	complianceLogList,
	findOne,
	updateOne
}