
import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.commonRoute.root

export const fileUploadS3 = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.commonRoute.fileUploadS3,
            method: endPoint.methodType.put
        })
    )?.data
}

export default {
    fileUploadS3
}