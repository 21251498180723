

import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.userProfileRoute.root


export const findOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.userProfileRoute.viewMe,
            method: endPoint.methodType.get
        })
    )?.data
}

export const update = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.userProfileRoute.updateMe,
            method: endPoint.methodType.put
        })
    )?.data
}

export const changePassword = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.userProfileRoute.changePassword,
            method: endPoint.methodType.patch
        })
    )?.data
}

export const changeNotificationSettings = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.userProfileRoute.changeNotificationSettings,
            method: endPoint.methodType.patch
        })
    )?.data
}

export const deleteAccount = async () => {

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.userProfileRoute.deleteMyAccount,
            method: endPoint.methodType.delete
        })
    )?.data
    
}

export default {
    findOne,
    update,
    changePassword,
    changeNotificationSettings,
    deleteAccount
}