// ** Auth Endpoints

export default {
  //loginEndpoint: '/api/v1/login',
  loginEndpoint: '/api/v1/authentication/web-app-login',
  loginStaffEndpoint: '/api/v1/authentication/mobile-app-login',
  // loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  // tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

 baseURL:process.env.REACT_APP_BASE_URL
//  process.env.REACT_APP_BASE_URL
  //baseURL:'http://176.58.116.31:3000's
}
