import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const setData = createAsyncThunk('appGlobleVariable/setData', async params => {
  return {
    selectedSiteID: params.selectedSiteID
  }
})


export const appInvoiceSlice = createSlice({
  name: 'appGlobleVariable',
  initialState: {
    selectedSiteID: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setData.fulfilled, (state, action) => {
      state.selectedSiteID = action.payload.selectedSiteID
    })
  }
})

export default appInvoiceSlice.reducer