import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { TemperatureAPIs } from '../../../../apis'

// const defaultParams = {
// 	q: "",
// 	page: 1,
// 	isParamChanged: true
// }

export const clearData = createAsyncThunk('appTemperatureLog/clearData', () => {
    return {
      data: [],
      allData: [],
      totalPages: 0
    }
  })
  
  
  export const setModalDataViewTask = createAsyncThunk('appTemperatureLog/setModalDataViewTask', async params => {
    return {
      modalDataViewTask: {
        isModalOpenView: params.isModalOpenView,
        logID: params.logID
        
      }
    }
  })

export const getData = createAsyncThunk('appTemperatureLog/getData', async params => {
    const response = await TemperatureAPIs.temperatureLogList({
      ...params,
		  search: params.q,
      parameter: {
        startDate:params.startDate,
        endDate:params.endDate,
        siteID:params.siteID,
        type:params.type
      }
    })

    return {
      params,
      data: response.data.list,
      allData: response.data.list,
      totalPages: response.data.records
	  }
})

export const getOneData = createAsyncThunk('appInvoice/getOneData', async params => {
	try {
		const response = await TemperatureAPIs.findOne(params)

		return {
			viewTempratureDetail: response.data
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const exportData = createAsyncThunk('appTemperatureLog/exportData', async params => {
  const response = await TemperatureAPIs.exportLogList({
      ...params,
  search: params.q,
  parameter: {
    siteID:params.siteID,
    type:params.type,
    startDate:params.startDate,
    endDate:params.endDate,
    downloadFileType:'EXCEL'
}

  })
  return {
  params,
  data: response.data,
	totalPages: response.records
 
}

})

export const appInvoiceSlice = createSlice({
    name: 'appTemperatureLog',
    initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      modalData: {
        isModelOpen :false,
        id: ''
      },
      modalDataViewTask: {
        isModalOpenView :false,
        logID:''
      },
      viewTempratureDetail: {},
      csvData: []
    },
    reducers: {},
    extraReducers: builder => {
      builder.addCase(getData.fulfilled, (state, action) => {
            if (action.payload.params.isParamChanged) {
                  state.data = action.payload.data
              } else {
                  state.data = [...state.data, ...action.payload.data]
              }
  
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
        state.params = action.payload.params
      }).addCase(exportData.fulfilled, (state, action) => {
        state.exportedData = action.payload.data
      })
      .addCase(setModalDataViewTask.fulfilled, (state, action) => {
        state.modalDataViewTask = action.payload.modalDataViewTask
      })
      .addCase(getOneData.fulfilled, (state, action) => {
        state.viewTempratureDetail = action.payload.viewTempratureDetail
      })
    }
  })
  
  export default appInvoiceSlice.reducer