import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.organizationRoute.root

export const List = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.organizationRoute.list
        })
    )?.data
}

export const createNewOne = async (parameters) => {
return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.organizationRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.organizationRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export const deleteOne = async (parameters) => {
    // const path = endPoint.departmentRoute.deleteOne.replace(':id', parameters.departmentID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.organizationRoute.deleteOne,
            method: endPoint.methodType.delete
        })
    )?.data
}

export const findOne = async (parameters) => {
    // const path = endPoint.departmentRoute.findOne.replace(':id', parameters.departmentID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.organizationRoute.findOne
        })
    )?.data
}

export const updateNote = async (parameters) => {
    // const path = endPoint.departmentRoute.findOne.replace(':id', parameters.departmentID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.organizationRoute.updateNote
        })
    )?.data
}

export default {
    List,
    createNewOne,
    updateOne,
    deleteOne,
    findOne,
    updateNote
}
