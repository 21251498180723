
import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"

const rootPath = endPoint.preDefinedTemplate.root

export const simpleList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.preDefinedTemplate.simpleList
        })
    )?.data
}


export const findOne = async (parameters) => {
    const path = endPoint.preDefinedTemplate.findOne.replace(':id', parameters.templateID)
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data 

}
export const importNewOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.preDefinedTemplate.importOne,
            method: endPoint.methodType.post
        })
    )?.data
}
export default {
    simpleList,
    findOne,
    importNewOne
}