import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { FoodItemAPIs } from '../../../../apis'

const defaultParams = {
	q: "",
	page: 1,
	isParamChanged: true
}

export const getFoodItemData = createAsyncThunk('appFoodItem/getFoodItemData', async params => {
	const foodItemType = "FOODITEM"
	const response = await FoodItemAPIs.webAppList({
		...params,
		search: params.q,
		type:foodItemType
	  })
	
	  return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: response.data.records
	  }
})

export const clearData = createAsyncThunk('appFoodItem/clearData', () => {
	return {
	  data: [],
	  allData: [],
	  totalPages: 0
	}
  })

  export const getOneData = createAsyncThunk('appFoodItem/getOneData', async params => {
	try {
	  const response = await FoodItemAPIs.findOne(params)
  
	  return {
		foodItemDetail: response.data
	  }
	} catch (error) {
	  showErrorToast(error)
	}
  })

  export const setModalData = createAsyncThunk('appFoodItem/setModalData', async params => {
	return {
	  modalData: {
		isModalOpen: params.isModalOpen,
		id: params._id
	  }
	}
  })

  export const addFoodItem = createAsyncThunk('appFoodItem/add', async (foodItemData, { dispatch, getState }) => {
	defaultParams.siteID = foodItemData.selectedSiteID
	delete foodItemData.selectedSiteID
	await FoodItemAPIs.createNewOne({ body: foodItemData })
	// await dispatch(getFoodItemData(defaultParams))
	await dispatch(getFoodItemData({
		...getState().foodItem.params
	}))
})

export const deleteFoodItem = createAsyncThunk('appFoodItem/deleteStaff', async (data, {dispatch, getState}) => {
	defaultParams.siteID = data.selectedSiteID
	await FoodItemAPIs.deleteOne({ foodItemID: data.id })
	// await dispatch(getFoodItemData(defaultParams))
	await dispatch(getFoodItemData({
		...getState().foodItem.params
	}))
	await dispatch(setModalData({_id : '', isModalOpen: false}))
	return data.id
})

  export const editFoodItem = createAsyncThunk('appSupplier/editSupplier', async (foodItemData, { dispatch }) => {
	await FoodItemAPIs.updateOne({ body: foodItemData })
	await dispatch(getFoodItemData(defaultParams))
  })

  export const probSlice = createSlice({
	name: 'appFoodItem',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		modalData: {
			isModelOpen :false,
			id: ''
		},
		foodItemDetail:{
			_id:null,
			name: "",
			type: "",
			siteID: ""
		}
		
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getFoodItemData.fulfilled, (state, action) => {
			console.log(state.data, '33377777777777')
			if (action.payload.params.isParamChanged) {
				state.data = action.payload.data
			} else {
				state.data = [...state.data, ...action.payload.data]
			}
			

			state.allData = action.payload.allData
			state.total = action.payload.totalPages
			state.params = action.payload.params
		})
		.addCase(getOneData.fulfilled, (state, action) => {
			state.foodItemDetail = action.payload.foodItemDetail
		})
		.addCase(setModalData.fulfilled, (state, action) => {
			state.modalData = action.payload.modalData
		  })
	}
})
export default probSlice.reducer
