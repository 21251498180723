import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.documentMangementRoute.root
// const commentRootpath = endPoint.commentTask.root
// const individualTask = endPoint.individualTask.root

export const createNewOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.documentMangementRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}

export const webAppList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.documentMangementRoute.webAppList
        })
    )?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.documentMangementRoute.findOne.replace(':id', parameters)
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.documentMangementRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export default {
    createNewOne,
    webAppList,
    findOne,
    updateOne
}
