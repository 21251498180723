import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.nearMissRoute.root


export const nearMissList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.nearMissRoute.webAppList
		})
	)?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.nearMissRoute.findOne.replace(':id', parameters.logID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.nearMissRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export default {
	nearMissList,
    findOne,
    updateOne
}