import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.probRoute.root

export const webAppList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.probRoute.webAppList
		})
	)?.data
}

export const createNewOne = async(parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.probRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.probRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export const deleteOne = async (parameters) => {
    const path = endPoint.probRoute.deleteOne.replace(':id', parameters.foodItemID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path,
            method: endPoint.methodType.delete
        })
    )?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.probRoute.findOne.replace(':id', parameters.foodItemID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}


export const simpleList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.probRoute.simpleList
        })
    )?.data
}

export default {
	webAppList,
	createNewOne,
	updateOne,
	deleteOne,
	simpleList,
	findOne
}
