import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ProbAPIs } from '../../../../apis'

const defaultParams = {
	q: "",
	page: 1,
	isParamChanged: true
}

export const getProbData = createAsyncThunk('appProb/getProbData', async params => {
	const probType = "PROB"
	const response = await ProbAPIs.webAppList({
		...params,
		search: params.q,
		type:probType
	  })
	
	  return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: response.data.records
	  }
})

export const clearData = createAsyncThunk('appProb/clearData', () => {
	return {
	  data: [],
	  allData: [],
	  totalPages: 0
	}
  })


  export const getOneData = createAsyncThunk('appProb/getOneData', async params => {
	try {
	  const response = await ProbAPIs.findOne(params)
  
	  return {
		probDetail: response.data
	  }
	} catch (error) {
	  showErrorToast(error)
	}
  })

  export const setModalData = createAsyncThunk('appProb/setModalData', async params => {
	return {
	  modalData: {
		isModalOpen: params.isModalOpen,
		id: params._id
	  }
	}
  })

  export const addProb = createAsyncThunk('appProb/addProb', async (probData, { dispatch, getState}) => {
	defaultParams.siteID = probData.selectedSiteID
	delete probData.selectedSiteID
	await ProbAPIs.createNewOne({ body: probData })
	// await dispatch(getProbData(defaultParams))
	await dispatch(getProbData({
		...getState().prob.params
	}))
})


export const deleteProb = createAsyncThunk('appProb/deleteStaff', async (data, {dispatch, getState}) => {
	defaultParams.siteID = data.selectedSiteID
	await ProbAPIs.deleteOne({ foodItemID: data.id })
	// await dispatch(getProbData(defaultParams))
	await dispatch(getProbData({
		...getState().prob.params
	}))
	await dispatch(setModalData({_id : '', isModalOpen: false}))
	return data.id
})

  export const editProb = createAsyncThunk('appProb/editProb', async (probData, { dispatch }) => {
	await ProbAPIs.updateOne({ body: probData })
	await dispatch(getProbData(defaultParams))
  })
  export const foodItemSlice = createSlice({
	name: 'appProb',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		modalData: {
			isModelOpen :false,
			id: ''
		},
		probDetail:{
			_id:null,
			name: "",
			type: "",
			siteID: ""
		}
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getProbData.fulfilled, (state, action) => {
			if (action.payload.params.isParamChanged) {
				state.data = action.payload.data
			} else {
				state.data = [...state.data, ...action.payload.data]
			}
			

			state.allData = action.payload.allData
			state.total = action.payload.totalPages
			state.params = action.payload.params
		})
		.addCase(getOneData.fulfilled, (state, action) => {
			state.probDetail = action.payload.probDetail
		})
		.addCase(setModalData.fulfilled, (state, action) => {
			state.modalData = action.payload.modalData
		  })
	}
})
export default foodItemSlice.reducer
