import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { FridgeAPIs } from '../../../../apis'

const defaultParams = {
	q: "",
	page: 1,
	isParamChanged: true
}
export const getFridgeData = createAsyncThunk('appFridge/getFridgeData', async params => {
	const fridgeType = "FRIDGE"
	const response = await FridgeAPIs.webAppList({
		...params,
		search: params.q,
		type:fridgeType
	  })
	
	  return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: response.data.records
	  }
})

export const clearData = createAsyncThunk('appFridge/clearData', () => {
	return {
	  data: [],
	  allData: [],
	  totalPages: 0
	}
  })

  export const getOneData = createAsyncThunk('appFridge/getOneData', async params => {
	try {
	  const response = await FridgeAPIs.findOne(params)
  
	  return {
		fridgeDetail: response.data
	  }
	} catch (error) {
	  showErrorToast(error)
	}
  })

  export const setModalData = createAsyncThunk('appFridge/setModalData', async params => {
	return {
	  modalData: {
		isModalOpen: params.isModalOpen,
		id: params._id
	  }
	}
  })
  
  export const deleteFridge = createAsyncThunk('appFridge/deleteFridge', async (_id, {dispatch}) => {
	await FridgeAPIs.deleteOne({ masterTypeID: _id })
	  await dispatch(getFridgeData(defaultParams))
	await dispatch(setModalData({_id : '', isModalOpen: false}))
	  return _id
  })


  export const addFridge = createAsyncThunk('appFridge/addFridge', async (fridgeData, { dispatch, getState }) => {
	defaultParams.siteID = fridgeData.selectedSiteID
	delete fridgeData.selectedSiteID
	await FridgeAPIs.createNewOne({ body: fridgeData })
	// await dispatch(getFridgeData(defaultParams))
	await dispatch(getFridgeData({
		...getState().fridge.params
	}))
  })

  export const editFridge = createAsyncThunk('appFridge/editFridge', async (fridgeData, { dispatch, getState }) => {
	defaultParams.siteID = fridgeData.selectedSiteID
	delete fridgeData.selectedSiteID
	await FridgeAPIs.updateOne({ body: fridgeData })
	// await dispatch(getFridgeData(defaultParams))
	await dispatch(getFridgeData({
		...getState().fridge.params
	}))
  })

  export const getOneDataBySiteID = createAsyncThunk(
	'appFridge/getOneDataBySiteID',
	async params => {
		try {
			const response = await FridgeAPIs.findBySiteID(params)
		
			return {
				fridgeData: response.data
			}
		  } catch (error) {
			showErrorToast(error)
		  }
	}
  )
  
  export const FridgeSlice = createSlice({
	name: 'appFridge',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		modalData: {
			isModelOpen :false,
			id: ''
		},
		fridgeDetail:{
			_id:null,
			temperatureItems:[],
			siteID: ""
		},
		fridgeData:{
			_id:null,
			temperatureItems:[],
			siteID: ""
		}
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getFridgeData.fulfilled, (state, action) => {
			if (action.payload.params.isParamChanged) {
				state.data = action.payload.data
			} else {
				state.data = [...state.data, ...action.payload.data]
			}
			state.allData = action.payload.allData
			state.total = action.payload.totalPages
			state.params = action.payload.params
		})
		.addCase(getOneData.fulfilled, (state, action) => {
			state.fridgeDetail = action.payload.fridgeDetail
		})
		.addCase(getOneDataBySiteID.fulfilled, (state, action) => {
			state.fridgeData = action.payload.fridgeData
		})
		.addCase(setModalData.fulfilled, (state, action) => {
			state.modalData = action.payload.modalData
		  })
	}
})
export default FridgeSlice.reducer
