
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {CommonAPIs} from '../apis'

export const fileUploadS3  = createAsyncThunk('appUserProfile/fileUploadS3', async (formData, {}) => {
	const result = await CommonAPIs.fileUploadS3({body: formData})
	return {
		location: result.data.location
	}

})

export const authSlice = createSlice({
	name: 'authentication',
	initialState: {
        location: ''
	},
	reducers: {},
    extraReducers: builder => {
        builder.addCase(fileUploadS3.fulfilled, (state, action) => {
            state.location = action.payload.location
        })
    }
})


export default authSlice.reducer
