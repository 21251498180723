// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { DepartmentAPIs } from '../../../../../apis'

const defaultParams = {
	q: "",
	page: 1,
	isParamChanged: true
}

export const getDepartmentData = createAsyncThunk('appDepartment/getData', async params => {
	const response = await DepartmentAPIs.webAppList({
		...params,
		search: params.q
	})

	return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: response.data.records
	}
})


export const getsimpleListDepartment = createAsyncThunk('appDepartment/simpleList', async (params) => {
	const response =  await DepartmentAPIs.simpleList({
		 ...params,
			 search: params.q
	  })
  
	  return {
		simpleList: response.data.list
	  }
  })

  export const getsimpleListDepartment2 = createAsyncThunk('appDepartment/simpleList2', async (params) => {
	const response =  await DepartmentAPIs.simpleList2({ body: params })
  
	  return {
		simpleList2: response.data.list
	  }
  })
  

export const clearData = createAsyncThunk('appDepartment/clearData', () => {
	return {
		data: [],
		allData: [],
		totalPages: 0
	}
})

export const removeData = createAsyncThunk('appDepartment/removeData', () => {
	return {
		data: [],
		allData: [],
		totalPages: 0
	}
})

export const addDepartment = createAsyncThunk('appDepartment/addInvoice', async (departmentData, { dispatch }) => {
	await DepartmentAPIs.createNewOne({ body: departmentData })
	await dispatch(getDepartmentData(defaultParams))
})

export const editDepartment = createAsyncThunk('appDepartment/edit', async (departmentData, { dispatch }) => {
	await DepartmentAPIs.updateOne({ body: departmentData })
	await dispatch(removeData())
})


export const setModalData = createAsyncThunk('appDepartment/setModalData', async params => {
	return {
	  modalData: {
		isModalOpen: params.isModalOpen,
		id: params._id
	  }
	}
  })

export const deleteInvoice = createAsyncThunk('appDepartment/deleteInvoice', async (_id, { dispatch }) => {
	await DepartmentAPIs.deleteOne({ departmentID: _id })
	await dispatch(getDepartmentData(defaultParams))
	await dispatch(setModalData({_id : '', isModalOpen: false}))
	return _id
})

export const getOneData = createAsyncThunk('appDepartment/getOneData', async params => {
	try {
		const response = await DepartmentAPIs.findOne(params)

		return {
			departmentDetail: response.data
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const appDepartmentSlice = createSlice({
	name: 'appDepartment',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		simpleList: [],
		simpleList2: [],
		modalData: {
			isModelOpen :false,
			id: ''
		  },
		departmentDetail: {
			_id: null,
			name: null,
			description: null
		},
		userList :[]
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getDepartmentData.fulfilled, (state, action) => {
			if (action.payload.params.isParamChanged) {
				state.data = action.payload.data
			} else {
				state.data = [...state.data, ...action.payload.data]
			}

			state.allData = action.payload.allData
			state.total = action.payload.totalPages
			state.params = action.payload.params
		})
			.addCase(getOneData.fulfilled, (state, action) => {
				state.departmentDetail = action.payload.departmentDetail
			})
			.addCase(removeData.fulfilled, (state, action) => {
				state.allData = action.payload.allData
				state.total = action.payload.totalPages
				state.data = action.payload.data
			})
			.addCase(setModalData.fulfilled, (state, action) => {
				state.modalData = action.payload.modalData
			})
			.addCase(getsimpleListDepartment.fulfilled, (state, action) => {
				state.simpleList = action.payload.simpleList
			})
			.addCase(getsimpleListDepartment2.fulfilled, (state, action) => {
				state.simpleList2 = action.payload.simpleList2
			})
	}
})

export default appDepartmentSlice.reducer
