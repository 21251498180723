import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.locationRoute.root

export const simpleListCountries = async () => {
    return (
        await axiosCallAdvanced({
            baseURL: rootPath,
            path: endPoint.locationRoute.simpleListCountries
        })
    )?.data
}

export const webAppList = async (parameters) => {
    console.log(parameters, '15')
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.locationRoute.webAppList
		})
	)?.data
}

export default {
    simpleListCountries,
    webAppList
}
