import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.freezerRoute.root


export const webAppList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.freezerRoute.webAppList
		})
	)?.data
}

export const createNewOne = async(parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.freezerRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.freezerRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export const deleteOne = async (parameters) => {
    const path = endPoint.freezerRoute.deleteOne.replace(':id', parameters.masterTypeID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path,
            method: endPoint.methodType.delete
        })
    )?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.freezerRoute.findOne.replace(':id', parameters.masterTypeID)
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export const simpleList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.freezerRoute.simpleList
        })
    )?.data
}


export const findBySiteID = async (parameters) => {
    // Destructure the siteID property from parameters and keep the rest of the properties
    const { siteID, ...restParameters } = parameters
  
    const path = endPoint.masterTypeRoute.findMasterTypeBySiteID.replace(':siteID', siteID)
    console.log(parameters, '75')
    return (
      await axiosCallAdvanced({
        ...restParameters, // Use restParameters instead of parameters
        baseURL: rootPath,
        path
      })
    )?.data
  }

export default {
	webAppList,
	createNewOne,
	updateOne,
	deleteOne,
	simpleList,
	findOne,
    findBySiteID
}