import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ComplianceAPIs, NearMissAPIs } from '../../../../apis'

export const clearData = createAsyncThunk('appComplianceLog/clearData', () => {
    return {
        data: [],
        allData: [],
        totalPages: 0
    }
})

export const clearNearMissData = createAsyncThunk('appNearMiss/clearNearMissData', () => {
    return {
        nearMissdata: [],
        nearMissallData: [],
        totalnearMisstotalPagesPages: 0
    }
})

export const setModalDataViewTask = createAsyncThunk('appComplianceLog/setModalDataViewTask', async params => {
    return {
        modalDataViewTask: {
            isModalOpenView: params.isModalOpenView,
            logID: params.logID
        }
    }
})

export const getData = createAsyncThunk('appComplianceLog/getData', async params => {
    const response = await ComplianceAPIs.complianceLogList({
        ...params,
        search: params.q,
        parameter: {
            startDate: params.startDate,
            endDate: params.endDate,
            siteID: params.siteID,
            type: params.type
        }
    })

    return {
        params,
        data: response.data.list,
        allData: response.data.list,
        totalPages: response.data.records
    }
})

export const getOneData = createAsyncThunk('appInvoice/getOneData', async params => {
    try {
        const response = await ComplianceAPIs.findOne(params)

        return {
            viewComplianceLogDetail: response.data
        }
    } catch (error) {
        showErrorToast(error)
    }
})

export const editComplianceLog = createAsyncThunk('appStaff/edit', async (complianceLogData, { dispatch }) => {
	await ComplianceAPIs.updateOne({ body: complianceLogData })
	await dispatch(clearData())
})

export const nearMissgetData = createAsyncThunk('appNearMiss/getData', async params => {
    const response = await NearMissAPIs.nearMissList({
        ...params,
        search: params.q,
        parameter: {
            startDate: params.startDate,
            endDate: params.endDate,
            siteID: params.siteID,
            type: params.type
        }
    })

    return {
        params,
        nearMissdata: response.data.list,
        nearMissallData: response.data.list,
        nearMisstotalPages: response.data.records
    }
})

export const getOneNearMissData = createAsyncThunk('appNearMiss/getOneNearMissData', async params => {
    try {
        const response = await NearMissAPIs.findOne(params)

        return {
            viewNearMissDetail: response.data
        }
    } catch (error) {
        showErrorToast(error)
    }
})

export const editNearMissData = createAsyncThunk('appNearMiss/editNearMissData', async (data, { dispatch }) => {
    console.log(data, "data")
	await NearMissAPIs.updateOne({ body: data })
	await dispatch(clearNearMissData())
})

export const appInvoiceSlice = createSlice({
    name: 'appComplianceLog',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        modalData: {
            isModelOpen: false,
            id: ''
        },
        modalDataViewTask: {
            isModalOpenView: false,
            logID: ''
        },
        viewComplianceLogDetail: {},
        csvData: [],
        nearMissdata: [],
        nearMissallData: [],
        nearMisstotalPages: 1,
        viewNearMissDetail: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getData.fulfilled, (state, action) => {
            if (action.payload.params.isParamChanged) {
                state.data = action.payload.data
            } else {
                state.data = [...state.data, ...action.payload.data]
            }

            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
        }).addCase(setModalDataViewTask.fulfilled, (state, action) => {
            state.modalDataViewTask = action.payload.modalDataViewTask
        }).addCase(getOneData.fulfilled, (state, action) => {
            state.viewComplianceLogDetail = action.payload.viewComplianceLogDetail
        }).addCase(nearMissgetData.fulfilled, (state, action) => {
            if (action.payload.params.isParamChanged) {
                state.nearMissdata = action.payload.nearMissdata
            } else {
                state.nearMissdata = [...state.nearMissdata, ...action.payload.nearMissdata]
            }

            state.nearMissallData = action.payload.nearMissallData
            state.nearMisstotalPages = action.payload.nearMisstotalPages
            state.params = action.payload.params
        }).addCase(getOneNearMissData.fulfilled, (state, action) => {
            state.viewNearMissDetail = action.payload.viewNearMissDetail
        })
    }
})

export default appInvoiceSlice.reducer