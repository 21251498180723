import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.authenticationRoute.root


export const forgotPassword = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.authenticationRoute.forgetPassword,
            method: endPoint.methodType.post
        })
    )?.data
}

export const  resetPassword = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.authenticationRoute.resetPassword,
            method: endPoint.methodType.put
        })
    )?.data
}

export const findOneAdminUser = async (parameters = {}) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.authenticationRoute.adminUserData,
            method: endPoint.methodType.get
        })
    )?.data
}

export const staffLogin = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.authenticationRoute.mobileAppLogin,
            method: endPoint.methodType.post
        })
    )?.data
}


export default {
    forgotPassword,
    resetPassword,
    findOneAdminUser
}