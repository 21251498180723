
// ** Redux Imports
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { StaffAPIs } from '../../../../../src/apis'

const defaultParams = {
    q: "",
    page: 1,
    isParamChanged: true
}

export const clearData = createAsyncThunk('appStaff/clearData', () => {
  return {
    data: [],
    allData: [],
    totalPages: 0
  }
})
export const getData = createAsyncThunk('appStaff/getData', async params => {
    const response = await StaffAPIs.webAppList({
        ...params,
		search: params.q
    })

    return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: response.data.records
	}

})

export const addStaff = createAsyncThunk('appStaff/add', async (staffData, { dispatch, getState }) => {
	await StaffAPIs.createNewOne({ body: staffData })
  const params = getState().staff.params
	await dispatch(getData(params))
})

export const resendInvite = createAsyncThunk('appStaff/add', async (staffData, { dispatch }) => {
	await StaffAPIs.resendInvite({ body: staffData })
	await dispatch(getData(defaultParams))
})


export const editStaff = createAsyncThunk('appStaff/edit', async (staffData, { dispatch }) => {
	await StaffAPIs.updateOne({ body: staffData })
	await dispatch(removeData())
})

export const getOneData = createAsyncThunk('appStaff/getOneData', async params => {
	try {
		const response = await StaffAPIs.findOne(params)

		return {
			staffDetail: response.data
		}
	} catch (error) {
		showErrorToast(error)
	}
})


export const setModalData = createAsyncThunk('appStaff/setModalData', async params => {
  return {
    modalData: {
      isModalOpen: params.isModalOpen,
      id: params._id
    }
  }
})


export const deleteStaff = createAsyncThunk('appStaff/deleteStaff', async (_id, {dispatch}) => {
  await StaffAPIs.deleteOne({ staffID: _id })
	await dispatch(getData(defaultParams))
  await dispatch(setModalData({_id : '', isModalOpen: false}))
	return _id
})

export const deleteStaffAccount = createAsyncThunk('appStaff/deleteStaff', async (_id) => {
  try {
    const response = await StaffAPIs.deleteOne({ staffID: _id })
    console.log('response', response)
    return {
      staffAccount: response
    }
  } catch (error) {
    showErrorToast(error)
  }
})

export const simpleList = createAsyncThunk('appStaff/simpleList', async (params) => {
  const response =  await StaffAPIs.simpleList({
       ...params,
		   search: params.q
    })
    response.data.list.sort((a, b) => {
      const nameA = `${a.userID.firstName} ${a.userID.lastName}`
      const nameB = `${b.userID.firstName} ${b.userID.lastName}`
      return nameA.localeCompare(nameB)
    })
    return {
      userList: response.data.list
    }
})

export const simpleList2 = createAsyncThunk('appStaff/simpleList2', async (params) => {
	const response =  await StaffAPIs.simpleList2({ body: params })
  response.data.list.sort((a, b) => {
    const nameA = `${a.userID.firstName} ${a.userID.lastName}`
    const nameB = `${b.userID.firstName} ${b.userID.lastName}`
    return nameA.localeCompare(nameB)
  })
  return {
    simpleList2: response.data.list,
    departmentArray: response.data.departmentArray
  }
  
	  // return {
		// simpleList2: response.data.list
	  // }
  })


export const statusUpdate = createAsyncThunk('appStaff/statusUpdate', async (staffData, { dispatch }) => {
	await StaffAPIs.updateStatus({ body: staffData })
	await dispatch(removeData())
})


export const downloadStaff = createAsyncThunk('appStaff/downloadStaff', async () => {
  const response =  await StaffAPIs.downloadStaffData({
       
    })

    return {
      staffData: response.data.list
    }
})

export const uploadStaff = createAsyncThunk('appStaff/uploadStaff', async (staffData, { dispatch }) => {
	await StaffAPIs.uploadStaff({ body: staffData })
	await dispatch(getData(defaultParams))
})

export const simpleTaskList = createAsyncThunk('appStaff/simpleTaskList', async (params) => {
  console.log("params : ", params)
  const response =  await StaffAPIs.getTasksForAssign({
    body: params
  })
  console.log(response, 'response')
  return {
    taskList: response.data
  }
})

export const bulkAssign = createAsyncThunk('appStaff/bulkAssign', async (taskData) => {
	await StaffAPIs.bulkAssign({ body: taskData })
})

export const appInvoiceSlice = createSlice({
  name: 'appStaff',
  initialState: {
    data: [],
    total: 1,
    params: {},
    staffAccount: {},
    allData: [],
    modalData: {
      isModelOpen :false,
      id: ''
    },
    userList: [],
    simpleList2: [],
    departmentArray: [],
    staffDetail: {
      firstName: '',
      lastName: '',
      email: '',
      userRole: '',
      departmentID: [],
      mobileNumber:'',
      gender:''
     
    },
    taskList: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      	if (action.payload.params.isParamChanged) {
				state.data = action.payload.data
			} else {
				state.data = [...state.data, ...action.payload.data]
			}

      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    }).addCase(getOneData.fulfilled, (state, action) => {
				state.staffDetail = action.payload.staffDetail
		})
    .addCase(setModalData.fulfilled, (state, action) => {
      state.modalData = action.payload.modalData
    })
    .addCase(simpleList.fulfilled, (state, action) => {
      state.userList = action.payload.userList
    })
    .addCase(simpleList2.fulfilled, (state, action) => {
      state.simpleList2 = action.payload.simpleList2
      state.departmentArray = action.payload.departmentArray
    })
    .addCase(downloadStaff.fulfilled, (state, action) => {
      state.staffData = action.payload.staffData
    })
    .addCase(deleteStaffAccount.fulfilled, (state, action) => {
      state.staffAccount = action.payload.staffAccount
    })
    .addCase(simpleTaskList.fulfilled, (state, action) => {
      state.taskList = action.payload.taskList
    })
  }
})

export default appInvoiceSlice.reducer