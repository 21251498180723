
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {UserProfileAPIs} from '../apis'


export const getOneData = createAsyncThunk('appUserProfile/getOneData', async () => {
	const result = await UserProfileAPIs.findOne()
	return {
        userData: result.data
    }
})

export const changePasswordAPIs  = createAsyncThunk('appUserProfile/changePassword', async (UserProfileData, {}) => {
	await UserProfileAPIs.changePassword({ body: UserProfileData })
	
})

export const changeNotificationSettingsAPIs  = createAsyncThunk('appUserProfile/changeNotificationSettings', async (UserProfileData, {}) => {
	await UserProfileAPIs.changeNotificationSettings({ body: UserProfileData })
	return {
        userData: result.data
    }
})

export const hadnleLogin = createAsyncThunk('appUserProfile/login',  (payload) => {

	sessionStorage.setItem('userData', JSON.stringify(action.payload))
	sessionStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))

	return {
		userData: payload,
		[config.storageTokenKeyName]: [config.storageTokenKeyName]
	}
})

export const editUserProfile  = createAsyncThunk('appUserProfile/edit', async (UserProfileData, {}) => {
	const result = await UserProfileAPIs.update({body:UserProfileData})

	const currentUser = sessionStorage.getItem('userData')
	const parsedUser = JSON.parse(currentUser || "{}")
	
	sessionStorage.setItem('userData', JSON.stringify({ ...parsedUser, userID: result.data.userID }))
	return {
		userData: { ...parsedUser, userID: result.data.userID }
	}

})

export const handleLogout = createAsyncThunk('appUserProfile/logout', () => {
	sessionStorage.removeItem('userData')
		sessionStorage.removeItem(config.storageTokenKeyName)
		// localStorage.clear()
		sessionStorage.clear()
		state.userData = {}

	return {
		[config.storageTokenKeyName]:null	
	}

})

const initialUser = () => {
	const item = window.sessionStorage.getItem('userData')
	//** Parse stored json or if none return initialValue
	return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
	name: 'authentication',
	initialState: {
	  userData: initialUser()
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getOneData.fulfilled, (state, action) => {
			state.userData = action.payload.userData
		}).addCase(editUserProfile.fulfilled, (state, action) => {
			state.userData = action.payload.userData
		}).addCase(changeNotificationSettingsAPIs.fulfilled, (state, action) => {
			state.userData = action.payload.userData
		})
	}
})


export default authSlice.reducer
