import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.taskCategoriesRoute.root

export const webAppList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskCategoriesRoute.webAppList
        })
    )?.data
}

export const createNewOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskCategoriesRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskCategoriesRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export const deleteOne = async (parameters) => {
    const path = endPoint.taskCategoriesRoute.deleteOne.replace(':id', parameters.taskCategoryID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path,
            method: endPoint.methodType.delete
        })
    )?.data
}

export const simpleList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskCategoriesRoute.simpleList
        })
    )?.data
}

export const simpleList2 = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskCategoriesRoute.simpleList2,
            method: endPoint.methodType.post
        })
    )?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.taskCategoriesRoute.findOne.replace(':id', parameters.taskCategoryID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export default {
    webAppList,
    createNewOne,
    updateOne,
    deleteOne,
    simpleList,
    simpleList2,
    findOne
}